.root {
  width: min(100%, 500px);
}

.content {
  padding: 20px 40px;
  overflow-y: hidden;

  min-height: 200px;
}

.submit {
  margin-top: 20px;
  width: 100%;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;

  z-index: 10;
}

.amountDescription {
  margin-top: 16px;

  font-size: 14px;
  font-weight: 500;
}
